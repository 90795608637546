.main-footer {
  margin-left: $sidebar-width;
  @extend .px-lg-3;
}

main.mode-icon {
  .main-footer {
    margin-left: $sidebar-mode-icon-width;
  }
}

@include media-breakpoint-down(md) {
  .main-footer {
    margin-left: 0;
  }

  main.mode-icon {
    .main-footer {
      margin-left: 0;
    }
  }
}