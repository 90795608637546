.dot-link {
  content: '';
  @extend .position-absolute;
  top: 14px;
  left: -6px;
  width: 10px;
  height: 10px;
  border: 2px solid $primary;
  @extend .rounded-circle;
}

.main-sidebar {
  @extend .position-fixed;
  @extend .top-0;
  @extend .start-0;
  width: $sidebar-width;
  height: 100vh;
  z-index: 1040;
  @extend .bg-white;
  @extend .shadow-sm;
  transition: $transition-base;
  .header {
    height: $sidebar-header-height;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .p-3;
    @extend .text-primary;
    .app-brand {
      @extend .h4;
      @extend .my-0;
      @extend .text-truncate;
      a {
        @extend .text-decoration-none;
        @extend .d-flex;
        @extend .align-items-center;
        .brand-logo {
          height: 50px;
        }
        .brand-label {
          @extend .ms-2;
          @extend .fw-bold;
          @extend .text-truncate;
        }
      }
    }
    .icon-toggler {
      @extend .pointer;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
  .body {
    height: $sidebar-body-height;
    max-height: $sidebar-body-height;
    overflow-y: auto;
    .nav {
      .nav-item {
        transition: $transition-base;
        .nav-link {
          @extend .d-flex;
          @extend .align-items-center;
          @extend .text-secondary;
          transition: $transition-base;
          padding: calc(#{var(--bs-nav-link-padding-y)} * 1.5) var(--bs-nav-link-padding-x);
          .nav-icon {
            @extend .d-flex;
            @extend .align-items-center;
            width: $sidebar-nav-icon-width;
            svg {
              width: 1.3rem;
              height: 1.3rem;
            }
          }
          &:hover {
            @extend .fw-bold;
          }
          &[aria-expanded="true"] {
            &::after {
              transform: rotate(90deg);
            }
          }
        }
        .nav-link.active {
          @extend .fw-bold;
        }
        .nav-link.has-sub {
          cursor: pointer;
          @extend .d-flex;
          @extend .align-items-center;
          &::after {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
            display: inline-block;
            margin-left: auto;
            width: 1.1rem;
            height: 1.1rem;
            transition: $transition-base;
          }
        }
        &:has(.nav-link[aria-expanded="true"]) {
          .collapse {
            .nav-item {
              @extend .position-relative;
              border-left: 2px solid $primary;
              .nav-link:not(.active) {
                transition: $transition-base;
                &::before {
                  @extend .dot-link;
                  @extend .bg-white;
                }
                &:hover {
                  &::before {
                    @extend .dot-link;
                    @extend .bg-primary;
                  }
                }
              }
              .nav-link.active {
                &::before {
                  @extend .dot-link;
                  @extend .bg-primary;
                }
              }
            }
          }
        }
      }
      .nav-children {
        padding-left: calc(#{$sidebar-nav-icon-width} / 1.4);
        .nav-item {
          .nav-link {
            padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
          }
        }
        .nav-children-sub {
          padding-left: calc(#{$sidebar-nav-icon-width} / 2.4);
        }
      }
    }
  }
}

main.mode-icon {
  .main-sidebar {
    &:not(:hover) {
      width: $sidebar-mode-icon-width;
      .header {
        .app-brand {
          a {
            .brand-logo {
              width: 35px;
              height: auto;
            }
            .brand-label {
              @extend .d-none;
            }
          }
        }
        .icon-toggler {
          @extend .d-none;
        }
      }
      .body {
        .nav {
          .nav-item {
            .nav-link {
              .nav-icon {
                @extend .justify-content-center;
                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
              .nav-label {
                @extend .d-none;
              }
            }
            .nav-link.active {
              @extend .bg-light;
            }
            .has-sub {
              &::after {
                content: '';
                display: none;
              }
            }
          }
          .nav-children {
            @extend .d-none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .main-sidebar {
    width: 100vw !important;
  }

  main.mode-icon {
    .main-sidebar {
      margin-left: -100vw !important;
    }
  }
}
