.main-navbar {
  background-color: $body-bg;
  left: $sidebar-width;
  transition: $transition-base;
  .navbar-collapse {
    @extend .mx-lg-3;
    @extend .px-3;
    @extend .py-2;
    @extend .rounded;
    @extend .shadow-sm;
    .navbar-nav {
      @extend .align-items-center;
      .nav-item {
        .nav-link {
          @extend .position-relative;
        }
      }
    }
    .navbar-search {
      width: auto;
      .input-group-text,
      .form-control {
        @extend .bg-transparent;
        @extend .border-0;
      }
      .input-group-text {
        .icon {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
      .form-control {
        @extend .rounded-0;
        @extend .border-bottom;
      }
    }
  }
  
  .account {
    @extend .ps-2;
    .dropdown-toggle {
      @extend .d-flex;
      @extend .align-items-center;
      .status {
        @extend .d-none;
        @extend .d-md-flex;
        @extend .flex-column;
        @extend .me-2;
        @extend .text-end;
        @extend .lh-sm;
      }
      .profile-picture {
        width: 40px;
        height: 40px;
        @extend .rounded-circle;
      }
      .badge-online {
        width: 13px;
        height: 13px;
        right: 7px;
        bottom: 7px;
        border: 2px solid $white;
      }
    }
    .dropdown-menu {
      .dropdown-item {
        @extend .d-flex;
        @extend .align-items-center;
        .icon {
          @extend .me-2;
        }
      }
    }
  }
}

main.mode-icon {
  .main-navbar {
    left: $sidebar-mode-icon-width;
  }
}

@include media-breakpoint-down(md) {
  .main-navbar {
    left: 0 !important;
  }
}
