.hide-scrollbar {
  overflow-y: hidden;
}

.pointer {
  cursor: pointer !important;
}

.centered {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.nowrap {
  white-space: nowrap !important;
}
