.main-content {
  flex: 1;
  margin-left: $sidebar-width;
  padding-top: calc(#{$sidebar-header-height} + 25px);
  @extend .pb-5;
  @extend .px-lg-3;
  transition: $transition-base;
}

main.mode-icon {
  .main-content {
    margin-left: $sidebar-mode-icon-width;
  }
}

@include media-breakpoint-down(md) {
  .main-content {
    margin-left: 0;
  }

  main.mode-icon {
    .main-content {
      margin-left: 0;
    }
  }
}
