.dropdown.no-caret {
  .nav-link {
    &::after {
      content: unset !important;
    }
  }
}

.dropdown.border-bottom-item {
  .dropdown-menu {
    .dropdown-item {
      &:not(:last-child) {
        @extend .border-bottom;
      }
    }
  }
}