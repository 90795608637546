$body-bg: #f8f9fa;
$font-family-base: 'Montserrat', sans-serif;
$font-size-base: .9rem;
$primary: #6ce34f;

// SIDEBAR
$sidebar-width: 300px;
$sidebar-mode-icon-width: 75px;
$sidebar-header-height: 75px;
$sidebar-body-height: calc(100vh - #{$sidebar-header-height});
$sidebar-nav-icon-width: 35px;